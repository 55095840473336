<template>
  <div>
    <h5 class="p-t-20 text-center bold p-b-10 auth-title">
      {{ $t("auth.reset password page") }}
    </h5>
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <form
        novalidate
        autocomplete="off"
        @submit.prevent="passes(resetPassword)"
      >
        <app-header-notice ref="notice"></app-header-notice>
        <label class="m-b-5 input-label control-label">
          {{ $t("common.new_password") }}
          <span class="app-require">＊</span>
        </label>
        <app-password
          input-style="normal"
          name="new_password"
          type="password"
          rules="required|password|min:6"
          v-model="new_password"
        ></app-password>
        <label class="m-b-5 m-t-10 input-label control-label">
          {{ $t("common.new_password_confirm") }}
          <span class="app-require" style="margin-left: -10px;">＊</span>
        </label>
        <app-password
          input-style="normal"
          name="new_password_confirm"
          type="password"
          :rules="`required|confirm_pass:${new_password}`"
          v-model="new_password_confirm"
        ></app-password>

        <br/><br/>
        <label class="notifi-resetpass">{{ $t("auth.password note 2") }}</label>
        <label class="notifi-resetpass">{{ $t("auth.password note 1") }}</label>
        <div class="text-center m-t-20">
        <button class="app-btn app-btn-blue" type="submit">
          {{ $t("auth.reset password") }}
        </button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { AUTH } from "@constants";

export default {
  name: "ResetPasswordPage",

  data() {
    return {
      new_password: null,
      new_password_confirm: null,
    };
  },

  async mounted() {
    this.$request
      .post(this.ENDPOINT.AUTH_CHECK_PARAMS_RESET_PASSWORD, {
        user_id: this.$route.query.id,
        token: this.$route.query.token,
      })
      .then((res) => {
        if (res.hasErrors()) {
          return this.__redirect("link-expired");
        }
      });
  },

  methods: {
    async resetPassword() {
      let loginRoute =
        AUTH[this.$store.state.auth.currentAuth.role].routes.login;
      const res = await this.$request.post(this.ENDPOINT.AUTH_RESET_PASSWORD, {
        id: this.$route.query.id,
        token: this.$route.query.token,
        password: this.new_password,
        password_confirmation: this.new_password_confirm,
      });

      if (!res.hasErrors()) {
        this.$router.push({ name: loginRoute, query: { changePass: "yes" } });
      } else {
        return this.__redirect("link-expired");
        // this.$refs.notice.setError(res.data.msg);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  @import "@assets/pages/login.scss";
</style>
